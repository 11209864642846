<app-header></app-header>
<app-page-title>{{ 'login.title' | translate }}</app-page-title>
<app-html-content [text]="loginPageText$ | async"></app-html-content>
<div class="mt--6"></div>
<app-login-form [username]="username"
                [communicationWay]="communicationWay$ | async"
                [contact]="communicationContact$ | async"></app-login-form>
<div class="mt--6"></div>
<app-footer-contact></app-footer-contact>
<app-footer-info></app-footer-info>
