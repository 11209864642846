<div matRipple
     class="wrapper"
     [class.lg]="lg"
     [class.active]="active"
     [style]="getBgImageStyle()"
     (click)="handleSelect()"
     (keydown.enter)="handleSelect()">
  <div class="title-row">
    <div class="title">{{title}}</div>
    <div *ngIf="lg"
         class="arrow"></div>
  </div>
</div>
