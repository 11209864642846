<div class="header-wrapper">
  <div class="powered-by">{{ 'header.powered_by' | translate }}</div>
  <div class="separator"></div>
  <div class="img-wrapper mr--3">
    @if (logo) {
      <img class="logo" [src]="logo" [alt]="logo"/>
    }
  </div>
  <app-lang-menu class="hidden sm_block"></app-lang-menu>
  <ng-content></ng-content>
</div>
