<app-form-wrapper [title]="(voted? 'voting.form_bg_voted' : 'voting.form_bg_vote') | translate">
  <ng-container *ngIf="isDeadlinePassed() else showVoting">
    <div class="title-xl">{{ 'voting.form_header' | translate }}</div>
    <div class="mt-xl deadline-notification">
      <i class="icon icon--status-warning icon--color--warning icon--size--20"></i>
      <div
        class="body-md">{{ 'voting.voting_ended' | translate:({voteDeadline: voteDeadlineStr}) }}
      </div>
    </div>
  </ng-container>
  <ng-template #showVoting>
    <div *ngIf="voted">
      <div class="body-lg voted-notification">{{ 'voting.voted_date' | translate:({votedDate: votedDateStr}) }}</div>
    </div>
    <div *ngIf="!voted">
      <div class="title-xl">{{ 'voting.form_header' | translate }}</div>
      <mat-radio-group class="radio-group mt-xl"
                       color="primary"
                       [(ngModel)]="votingDecision">
        <mat-radio-button [value]="'yes'">{{ 'voting.accept' | translate }}</mat-radio-button>
        <mat-radio-button [value]="'no'">{{ 'voting.decline' | translate }}</mat-radio-button>
      </mat-radio-group>
      <button mat-raised-button
              class="mt-xl"
              color="primary"
              [disabled]="previewMode"
              (click)="onDecisionDone()"> {{ 'voting.submit_vote' | translate }}
      </button>
      <div *ngIf="voteDeadline" class="mt-xl deadline-notification">
        <i class="icon icon--status-alert-info icon--size--20 bg-primary"></i>
        <div class="body-md">{{ 'voting.vote_till_deadline' | translate:({voteDeadline: voteDeadlineStr}) }}</div>
      </div>
    </div>
  </ng-template>
</app-form-wrapper>
