import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ContactInfo} from '../store/contact/contact.model';
import {PortalInfo} from '../store/portal/portal.model';
import {getActualLanguage} from '../functions/translate-utils';

enum ResponseType {
  BLOB = 'blob',
}

enum Observe {
  RESPONSE = 'response',
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  getContactData(username: string): Observable<ContactInfo> {
    return this.http.get<ContactInfo>(
      `${environment.publicApiUrl}/login-page/${username}?language=${getActualLanguage().toUpperCase()}`,
      {withCredentials: true},
    );
  }

  getPortalData(): Observable<PortalInfo> {
    return this.http.get<PortalInfo>(
      `${environment.portalApiUrl}/voting_portal/participation_law?language=${getActualLanguage().toUpperCase()}`,
      {withCredentials: true},
    );
  }

  requestLoginCode(username: string): Observable<Object> {
    return this.http.get(`${environment.publicApiUrl}/send-login-code/${username}`, {withCredentials: true});
  }

  login({username, password}: {username: string, password: string}): Observable<Object> {
    const data = new FormData();
    data.append('username', username);
    data.append('password', password);
    return this.http.post(
      `${environment.portalApiUrl}/login`,
      data,
      {withCredentials: true},
    );
  }

  makeVote(accepted: boolean): Observable<Object> {
    return this.http.post(`${environment.portalApiUrl}/voting_portal/vote`, {accepted}, {withCredentials: true});
  }

  downloadDocument(documentId: string): Observable<Object> {
    return this.http.get(`${environment.portalApiUrl}/voting_portal/download/${documentId}`, {
      responseType: ResponseType.BLOB,
      observe: Observe.RESPONSE,
      withCredentials: true,
    });
  }

  logout(): Observable<Object> {
    return this.http.post(
      `${environment.portalApiUrl}/logout`,
      {},
      {withCredentials: true},
    );
  }

}
