import {Component} from '@angular/core';
import {
  CommonModule,
  NgOptimizedImage,
} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {LangMenuComponent} from '../lang-menu/lang-menu.component';
import {AppState} from '../../store/store';
import {selectBrokerLogo} from '../../store/contact/contact.selectors';
import {HeaderMenuDialogComponent} from '../header-menu-dialog/header-menu-dialog.component';
import {HeaderPanelComponent} from '../header-panel/header-panel.component';
import {SquareIconButtonComponent} from '../square-icon-button/square-icon-button.component';
import {logout} from '../../store/auth/auth.actions';
import {selectLoggedIn} from '../../store/auth/auth.selectors';
import {CommonSpacerComponent} from '../common-spacer/common-spacer.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatFormFieldModule, MatSelectModule, MatMenuModule, MatIconModule,
    LangMenuComponent, NgOptimizedImage, HeaderPanelComponent, SquareIconButtonComponent, TranslateModule,
    CommonSpacerComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
  ) {
  }

  readonly brokerLogo$ = this.store.select(selectBrokerLogo);

  readonly loggedIn$ = this.store.select(selectLoggedIn);

  handleLogout(): void {
    this.store.dispatch(logout());
  }

  showMobileMenu(): void {
    this.dialog.open(HeaderMenuDialogComponent, {
      position: {top: '0'},
      minWidth: '100%',
      data: {},
    });
  }

}
