import {createSelector} from '@ngrx/store';
import {ContactState} from './contact.model';
import {DEFAULT_LANG_KEY} from '../../functions/translate-utils';

export const contactFeatureKey = 'contact';
const fixImageUri = (uri: string): string => {
  if (uri) {
    if (!(/base64/).test(uri) && !uri.startsWith('http')) {
      // attempt to fix data uri to png
      return `data:image/jpeg;base64,${uri}`;
    }
    return uri;
  }
  return uri;
};

interface AppState {
  [contactFeatureKey]: ContactState
}

export const selectContactState = (state: AppState): ContactState => state[contactFeatureKey];
export const selectContactPerson = createSelector(
  selectContactState,
  state => state.contactData?.contactPerson,
);
export const selectIsPublicDataLoaded = createSelector(
  selectContactState,
  state => !!state.contactData,
);
export const selectBrokerName = createSelector(
  selectContactState,
  state => state?.contactData?.brokerName || '',
);
export const selectCommunicationWay = createSelector(
  selectContactState,
  state => state?.contactData?.communicationWaySent || '',
);
export const selectCommunicationContact = createSelector(
  selectContactState,
  state => state?.contactData?.contact || '',
);
export const selectLoginPageText = createSelector(
  selectContactState,
  state => state?.contactData?.loginPage || '',
);
export const selectContactLanguage = createSelector(
  selectContactState,
  state => state?.contactData?.language?.toLowerCase() || DEFAULT_LANG_KEY,
);

export const selectBrokerLogo = createSelector(
  selectContactState,
  state => fixImageUri(state?.contactData?.brokerLogo || ''),
);
export const selectContactAvatar = createSelector(
  selectContactPerson,
  state => fixImageUri(state?.avatar || ''),
);
export const selectContactName = createSelector(
  selectContactPerson,
  state => state?.fname,
);
export const selectContactPhone = createSelector(
  selectContactPerson,
  state => state?.phone,
);
export const selectContactRole = createSelector(
  selectContactPerson,
  state => state?.role,
);
export const selectContactEmail = createSelector(
  selectContactPerson,
  state => state?.email,
);
export const selectFooterData = createSelector({
  avatar: selectContactAvatar,
  fname: selectContactName,
  role: selectContactRole,
  phone: selectContactPhone,
  email: selectContactEmail,
});
