<button mat-stroked-button
        class="wide-button"
        [class.full-width]="fullWidth"
        [matMenuTriggerFor]="langMenu">
  <mat-icon class="select-icon" iconPositionEnd>expand_more</mat-icon>
  {{ 'langs.' + translateService.currentLang | translate }}
</button>

<mat-menu #langMenu="matMenu">
  <ng-container *ngFor="let lang of allLanguages">
    <button mat-menu-item
            [disabled]="previewMode$ | async"
            (click)="onSetLang(lang)">{{ 'langs.' + lang | translate }}</button>
  </ng-container>
</mat-menu>
