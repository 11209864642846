import {createSelector} from '@ngrx/store';
import {PortalState} from './portal.model';
import {DEFAULT_LANG_KEY} from '../../functions/translate-utils';

export const portalFeatureKey = 'portal';

interface AppState {
  [portalFeatureKey]: PortalState;
}

export const selectPortalState = (state: AppState): PortalState => state[portalFeatureKey];
export const selectIsPortalDataLoaded = createSelector(
  selectPortalState,
  state => !!state.portalData,
);
export const selectVotedState = createSelector(
  selectPortalState,
  state => !!state.portalData?.voted,
);
export const selectVotedDate = createSelector(
  selectPortalState,
  state => state.portalData?.votingDate || '',
);
export const selectDeadlineEnabled = createSelector(
  selectPortalState,
  state => !!state.portalData?.deadLineEnabled,
);
export const selectVoteDeadlineDate = createSelector(
  selectPortalState,
  state => state.portalData?.deadLineAt || '',
);
export const selectVotingFormData = createSelector({
  voted: selectVotedState,
  votedDate: selectVotedDate,
  deadline: selectVoteDeadlineDate,
  deadLineEnabled: selectDeadlineEnabled,
});
export const selectHomePage = createSelector(
  selectPortalState,
  state => state.portalData?.homePage || '',
);
export const selectPortalLanguage = createSelector(
  selectPortalState,
  state => state.portalData?.language?.toLowerCase() || DEFAULT_LANG_KEY,
);
export const selectBvgEnabled = createSelector(
  selectPortalState,
  state => !!state.portalData?.bvgEnabled,
);
export const selectAnalyticsEnabled = createSelector(
  selectPortalState,
  state => !!state.portalData?.personalAnalytic,
);
export const selectDocsEnabled = createSelector(
  selectPortalState,
  state => !!state.portalData?.additionalDocuments,
);
export const selectDocumentsList = createSelector(
  selectPortalState,
  state => state.portalData?.documents || [],
);
export const selectAdviceEnabled = createSelector(
  selectPortalState,
  state => !!state.portalData?.pensionAdviseEnabled,
);
export const selectPensionCommission = createSelector(
  selectPortalState,
  state => state.portalData?.pensionCommission || '',
);
export const selectBrokerAdvice = createSelector(
  selectPortalState,
  state => state.portalData?.brokerAdvise || '',
);
export const selectNavigationData = createSelector({
  bvg: selectBvgEnabled,
  docs: selectDocsEnabled,
  analytics: selectAnalyticsEnabled,
  advice: selectAdviceEnabled,
});

export const selectPersonalAnalyticsData = createSelector(
  selectPortalState,
  state => state.portalData?.personalAnalyticsPayload || '',
);

export const selectPreviewMode = createSelector(
  selectPortalState,
  state => state.portalData?.previewMode,
);
