import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import {NumberConverter} from '../functions/number-converter';
import {Constants} from '../constants/constants';

@Pipe({
  name: 'roundPercentage',
  standalone: true,
})
export class RoundPercentagePipe implements PipeTransform {

  transform(value: number): string {
    return value === null ? Constants.DASH : NumberConverter.roundNumber(value) + Constants.PERCENT;
  }

}
