import {
  ActivatedRouteSnapshot,
  Routes,
} from '@angular/router';
import {inject} from '@angular/core';
import {LoginComponent} from './pages/login/login.component';
import {AuthGuard} from './guards/canActivate';
import {VotingComponent} from './pages/voting/voting.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {BvgComponent} from './pages/bvg/bvg.component';
import {AnalyticsComponent} from './pages/analytics/analytics.component';
import {DocsComponent} from './pages/docs/docs.component';
import {AdviceComponent} from './pages/advice/advice.component';
import {NoAccessComponent} from './pages/no-access/no-access.component';
import {ContentPages} from './enums/content-page';

export const routes: Routes = [
  {
    path: 'preview',
    component: VotingComponent,
  },
  {
    path: `preview/${ContentPages.BVG}`,
    component: BvgComponent,
  },
  {
    path: `preview/${ContentPages.ANALYTICS}`,
    component: AnalyticsComponent,
  },
  {
    path: `preview/${ContentPages.DOCS}`,
    component: DocsComponent,
  },
  {
    path: `preview/${ContentPages.ADVICE}`,
    component: AdviceComponent,
  },
  {
    path: 'preview',
    component: VotingComponent,
  },
  {
    path: `preview/${ContentPages.BVG}`,
    component: BvgComponent,
  },
  {
    path: `preview/${ContentPages.ANALYTICS}`,
    component: AnalyticsComponent,
  },
  {
    path: `preview/${ContentPages.DOCS}`,
    component: DocsComponent,
  },
  {
    path: `preview/${ContentPages.ADVICE}`,
    component: AdviceComponent,
  },
  {
    path: ':username/login',
    component: LoginComponent,
  },
  {
    path: `:username/${ContentPages.BVG}`,
    component: BvgComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot) => inject(AuthGuard).canActivateRouteForLoggedInUsers(route),
      (route: ActivatedRouteSnapshot) => inject(AuthGuard).canGoToBVG(route),
    ],
  }, {
    path: `:username/${ContentPages.ANALYTICS}`,
    component: AnalyticsComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot) => inject(AuthGuard).canActivateRouteForLoggedInUsers(route),
      (route: ActivatedRouteSnapshot) => inject(AuthGuard).canGoToAnalytics(route),
    ],
  }, {
    path: `:username/${ContentPages.DOCS}`,
    component: DocsComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot) => inject(AuthGuard).canActivateRouteForLoggedInUsers(route),
      (route: ActivatedRouteSnapshot) => inject(AuthGuard).canGoToDocs(route),
    ],
  },
  {
    path: `:username/${ContentPages.ADVICE}`,
    component: AdviceComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot) => inject(AuthGuard).canActivateRouteForLoggedInUsers(route),
      (route: ActivatedRouteSnapshot) => inject(AuthGuard).canGoToAdvice(route),
    ],
  },
  {
    path: ':username',
    component: VotingComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot) => inject(AuthGuard).canActivateRouteForLoggedInUsers(route),
    ],
  },
  {
    path: ':username/not-found',
    component: NotFoundComponent,
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
