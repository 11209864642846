<div class="container">
  <img *ngIf="!iOsMode" src="assets/icons/status-alert-info_bg.svg" class="mr--2">
  <span>{{ 'analytics.footer_info.all_amounts_are_to_be_understood_in_chf' | translate }}</span>
</div>
<div class="container">
  <img *ngIf="!iOsMode" src="assets/icons/status-alert_bg.svg" class="mr--2">
  <span class="info-text">
  <span class="disclaimer"> {{ 'analytics.footer_info.disclaimer' | translate }}</span>
    <span> {{ 'analytics.footer_info.only_calculation_pension_funds_are_legally_binding' | translate }}</span>
  </span>
</div>
