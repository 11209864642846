import {createSelector} from '@ngrx/store';
import {AuthState} from './auth.reducers';

export const authFeatureKey = 'auth';

interface AppState {
  [authFeatureKey]: AuthState;
}

export const selectAuth = (state: AppState): AuthState => state[authFeatureKey];
export const selectLoggedIn = createSelector(
  selectAuth,
  state => state.loggedIn,
);

export const selectLoginChecked = createSelector(
  selectAuth,
  state => state.loginChecked,
);
