import {
  authReducer,
  AuthState,
} from './auth/auth.reducers';
import {authFeatureKey} from './auth/auth.selectors';
import {contactFeatureKey} from './contact/contact.selectors';
import {contactReducer} from './contact/contact.reducers';
import {portalFeatureKey} from './portal/portal.selectors';
import {portalReducer} from './portal/portal.reducers';
import {ContactState} from './contact/contact.model';
import {PortalState} from './portal/portal.model';

export interface AppState {
  [authFeatureKey]: AuthState,
  [contactFeatureKey]: ContactState,
  [portalFeatureKey]: PortalState
}

export const store = {
  [authFeatureKey]: authReducer,
  [contactFeatureKey]: contactReducer,
  [portalFeatureKey]: portalReducer,
};
