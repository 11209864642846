<div class="header-wrapper" [class.pdf-mode]="pdfMode">
  <div class="avatar-container">
    <img *ngIf="!iOsMode" src="assets/icons/avatar-default_bg.svg">
    <div class="person-name"> {{ personalData?.name }}</div>
  </div>
  <div class="mt-xxl"></div>

  <div class="person-data">

    <div class="item">
      <div class="label"> {{ getLabel('personalData.birthdate') }}</div>
      <div class="text"> {{ personalData?.birthdate | date : Constants.FORMAT_DATE_PPL }}</div>
    </div>
    <div class="item">
      <div class="label"> {{ getLabel('personalData.category') }}</div>
      <div class="text"> {{ personalData?.category }}</div>
    </div>

    <div class="item">
      <div class="label"> {{ getLabel('personalData.age') }}</div>
      <div class="text"> {{ personalData?.age }}</div>
    </div>
    <div class="item">
      <div class="label"> {{ getLabel('personalData.avhSalary') }}</div>
      <div class="text">
        <span class="currency">{{ Constants.CHF }}</span>
        <span>{{ personalData?.avhSalary | pasteApostropheAfterEachThreeNums }}</span>
      </div>
    </div>

    <div class="item">
      <div class="label"> {{ getLabel('personalData.jobSum') }}</div>
      <div class="text"> {{ personalData?.jobSum + Constants.PERCENT }}</div>
    </div>

  </div>

  <div class="mt-xl"></div>

</div>
