<app-header></app-header>
<app-page-title>{{ 'voting.title' | translate }}</app-page-title>
<app-common-spacer></app-common-spacer>
<app-html-content [text]="homePage$ | async"></app-html-content>
<app-common-spacer></app-common-spacer>
<app-content-sections [lg]="true"></app-content-sections>
@if (votingFormData$ | async; as formData) {
  <div *ngIf="formData.deadLineEnabled"></div>
  <app-voting-form *ngIf="formData.deadLineEnabled"
                   [voted]="formData.voted"
                   [votedDate]="formData.votedDate"
                   [voteDeadline]="formData.deadline"
                   [deadLineEnabled]="formData.deadLineEnabled"
                   [previewMode]="previewMode$ | async"
                   (decisionMade)="handleVoting($event)"></app-voting-form>
} @else {
  <div class="page-loader">
    <mat-spinner></mat-spinner>
  </div>
}
<div class="mt-3xl"></div>
<app-footer-contact></app-footer-contact>
<div class="mt-3xl"></div>
<app-footer-info></app-footer-info>
<div class="mt-3xl"></div>
