<div [class.pdf-mode]="pdfMode">
<div class="header">
  <div class="data-type">{{ getTranslationKeyByKey(rootTranslationKey) }}
  </div>
  <div class="column current-column"
       [class.pension-info-column]="isPensionInfoData()">{{ getTranslationKeyByKey('is') }}
  </div>
  <div class="column new-column"
       [class.pension-info-column]="isPensionInfoData()">{{ getTranslationKeyByKey('new') }}
  </div>
  <ng-container *ngIf="isPensionInfoData() else doubleColumns">
    <div class="column other-column"
         [class.pension-info-column]="isPensionInfoData()">{{ getTranslationKeyByKey('diff') }}
    </div>
  </ng-container>
  <ng-template #doubleColumns>
    <div class="column other-column percentage-column">{{ Constants.PERCENT }}</div>
    <div class="column other-column chf-column">{{ Constants.CHF }}</div>
  </ng-template>
</div>
<div class="row" [class.pdf-mode]="pdfMode" *ngFor="let key of getDataKeys()">
  <div class="data-type">
    <span>{{ getTranslationTitle(key) }}</span>
    <div class="icon-wrapper" *ngIf="getTranslationForRemark(key)">
      <div *ngIf="(isMobileView() && !iOsMode) else desktopRemark">
        <img src="assets/icons/status-info_bg.svg"
             (click)="openBottomSheet(getTranslationTitle(key), getTranslationForRemark(key))">
      </div>
      <ng-template #desktopRemark>
        <img *ngIf="!iOsMode" src="assets/icons/status-info_bg.svg"
             [matTooltip]="getTranslationForRemark(key)">
      </ng-template>
    </div>
  </div>
  <ng-container *ngIf="isPensionInfoData() else otherOption">
    <div class="cell pension-info-cell">{{ getTranslationValue(getStringValues(key).current) }}</div>
    <div class="cell pension-info-cell">{{ getTranslationValue(getStringValues(key).offer) }}</div>
  </ng-container>
  <ng-template #otherOption>
    <ng-container *ngIf="isEmployeeContributionsPctData(key) else withCHF">
      <div class="cell">{{ getNumericValue(key).current | roundPercentage }}
      </div>
      <div class="cell">{{ getNumericValue(key).offer | roundPercentage }}
      </div>
    </ng-container>
    <ng-template #withCHF>
      <div class="cell">
        <span
          class="currency">{{ Constants.CHF }}</span>{{ getNumericValue(key).current | pasteApostropheAfterEachThreeNums }}
      </div>
      <div class="cell"><span
        class="currency">{{ Constants.CHF }}</span>{{ getNumericValue(key).offer | pasteApostropheAfterEachThreeNums }}
      </div>
    </ng-template>
  </ng-template>
  <ng-container *ngIf="isPensionInfoData() else doubleCells">
    <div class="cell other-cell"
         [class.pension-info-cell]="isPensionInfoData()">{{ getTranslationForBoolean(getStringValues(key).difference) }}
    </div>
  </ng-container>
  <ng-template #doubleCells>
    <div class="cell other-cell percentage-cell">{{ getNumericValue(key).percent | roundPercentage }}</div>
    <div class="cell other-cell chf-cell">
      <ng-container *ngIf="isEmployeeContributionsPctData(key) else withCHF">
        {{ getNumericValue(key).difference  | roundPercentage }}
      </ng-container>
      <ng-template #withCHF>
        <span
          class="currency">{{ Constants.CHF }}</span>{{ getNumericValue(key).difference | pasteApostropheAfterEachThreeNums }}
      </ng-template>
    </div>
  </ng-template>
</div>
</div>
<div class="pb-xl"></div>
