import {
  Component,
  Input,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {AppState} from '../../store/store';
import {
  selectNavigationData,
  selectPreviewMode,
} from '../../store/portal/portal.selectors';
import {NavCardComponent} from '../nav-card/nav-card.component';
import {ContentPages} from '../../enums/content-page';
import {navigateWithSelfData} from '../../functions/utils';

@Component({
  selector: 'app-content-sections',
  standalone: true,
  imports: [CommonModule, NavCardComponent, TranslateModule],
  templateUrl: './content-sections.component.html',
  styleUrl: './content-sections.component.scss',
})
export class ContentSectionsComponent {

  @Input() lg = false;

  readonly ContentPages = ContentPages;

  public activePage = '';

  public navigationState$ = this.store.select(selectNavigationData);

  public previewMode$ = this.store.select(selectPreviewMode);

  private previewMode: boolean = false;

  constructor(private store: Store<AppState>, private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.url.subscribe(segments => {
      this.activePage = segments[segments.length - 1]?.path || '';
    });
  }

  async goTo(page: ContentPages): Promise<void> {
    this.previewMode$.subscribe(mode => {
      this.previewMode = mode;
    });
    if (this.previewMode) {
      await navigateWithSelfData(this.router, this.activatedRoute.queryParams, ['preview', page]);
    } else {
      const username = this.activatedRoute?.snapshot.paramMap.get('username');
      if (username) {
        await this.router.navigate([username, page]);
      }
    }
  }

}
