<div class="document-card">
  <div class="icon-box">
    <i class="icon-image" [ngClass]="getNameIconByFileTitle(document?.title)"></i>
  </div>
  <div class="title-group">
    <div class="document-title"
         [matTooltip]="document?.title"
         [matTooltipPosition]="'above'">
      {{ document?.title }}
    </div>
    @if (!previewMode) {
      <div class="download-group" (click)="downloadDocumentById(document?.id)">
        <i class="icon icon--download icon--color--broker icon--size--16"></i>
        <div class="download-title">{{ 'title.download' | translate }}</div>
      </div>
    }
  </div>
</div>
