<app-header></app-header>
<app-back-button class="block mb-lg"></app-back-button>
<app-page-title>{{ 'advice.title' | translate }}</app-page-title>
<app-common-spacer></app-common-spacer>
<div class="title-xl mb-lg">{{ 'advice.commission_subtitle' | translate }}</div>
<app-html-content [text]="pensionCommission$ | async"></app-html-content>
<app-common-spacer></app-common-spacer>
<div class="title-xl mb-lg">{{ 'advice.broker_subtitle' | translate:({brokerName: brokerName$ | async}) }}</div>
<app-html-content [text]="brokerAdvice$ | async"></app-html-content>
<app-common-spacer></app-common-spacer>
<app-content-sections></app-content-sections>
<div class="mt-3xl"></div>
<app-footer-info></app-footer-info>
