import {
  Component,
  Input,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {MatButtonModule} from '@angular/material/button';
import {
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {AppState} from '../../store/store';
import {FormWrapperComponent} from '../form-wrapper/form-wrapper.component';
import {
  login,
  requestCode,
} from '../../store/auth/auth.actions';
import {countdown} from '../../functions/utils';
import {AlertsService} from '../../services/alerts.service';
import {CommunicationWay} from '../../store/contact/contact.model';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [CommonModule, FormWrapperComponent, MatButtonModule, ReactiveFormsModule, MatInputModule, TranslateModule],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss',
})
export class LoginFormComponent {

  constructor(private store: Store<AppState>, private alerts: AlertsService, private translate: TranslateService) {
  }

  @Input() username = '';

  @Input() communicationWay: string | null = '';

  @Input() contact: string | null = '';

  public step = 1;

  public cooldown = new BehaviorSubject<number>(0);

  protected readonly CommunicationWay = CommunicationWay;

  public codeControl = new FormControl('', [
    Validators.required,
  ]);

  handleCodeGet(): void {
    this.store.dispatch(
      requestCode({
        username: this.username,
      }),
    );
    this.step = 2;
  }

  handleLogin(): void {
    if (this.codeControl.invalid) {
      this.alerts.showInfo({
        statusText: this.translate.instant('login.invalid_code_error_type'),
        message: this.translate.instant('login.please_provide_your_login_code'),
      });
      return;
    }
    this.store.dispatch(
      login({
        username: this.username,
        password: this.codeControl.value || '',
      }),
    );
  }

  handleTryAgain(): void {
    this.handleCodeGet();
    countdown(20).subscribe(seconds => {
      this.cooldown.next(seconds);
    });
  }

}
