import {
  ActionReducer,
  createReducer,
  on,
} from '@ngrx/store';
import * as ContactActions from './contact.actions';
import {ContactState} from './contact.model';

export const initialState: ContactState = {
  contactData: undefined,
};

export const contactReducer: ActionReducer<ContactState> = createReducer(
  initialState,
  on(ContactActions.contactDataLoaded, (state, {data}): ContactState => ({...state, contactData: data})),
);
