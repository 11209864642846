import {
  createAction,
  props,
} from '@ngrx/store';
import {Params} from '@angular/router';
import {ContactInfo} from './contact.model';

export const loadContactData = createAction(
  '[Contact] Get contact data',
);

export const contactDataLoaded = createAction(
  '[Contact] Contact data loaded',
  props<{data: ContactInfo}>(),
);

export const loadContactDataOrPortalDataAfterSwitchLanguage = createAction(
  '[Contact][Portal] Get contact or portal data after switch language',
);

export const postSelfData = createAction(
  '[Contact] [Portal] Post self data',
  props<{data: Params}>(),
);
