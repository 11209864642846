<app-header></app-header>
<app-back-button class="block mb-lg"></app-back-button>
<app-page-title>{{ 'docs.title' | translate }}</app-page-title>
<app-common-spacer></app-common-spacer>
<div class="documents-lists">
  <app-document-card class="item-doc" *ngFor="let document of additionalDocumentsList"
                     [previewMode]="previewMode$ | async"
                     [document]="document"
                     (downloadDocument)="downloadDocument($event)">
  </app-document-card>
</div>
<app-common-spacer></app-common-spacer>
<app-content-sections></app-content-sections>
<div class="mt-3xl"></div>
<app-footer-info></app-footer-info>
