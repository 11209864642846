import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IDocumentDto} from '../../store/portal/portal.model';
import {Constants} from '../../constants/constants';
import {FileType} from '../../enums/file.enum';

@Component({
  selector: 'app-document-card',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatTooltipModule],
  templateUrl: './document-card.component.html',
  styleUrl: './document-card.component.scss',
})
export class DocumentCardComponent {

  @Input() document: IDocumentDto;

  @Input() previewMode: boolean = false;

  @Output() downloadDocument: EventEmitter<string> = new EventEmitter<string>();

  getNameIconByFileTitle(title: string): string {
    return this.getIconNameByFileType(this.getFileTypeByFileName(title));
  }

  getFileTypeByFileName(fileName: string): string {
    const arrFileName = fileName.split(Constants.DOT);
    return arrFileName[arrFileName.length - 1] || null;
  }

  getIconNameByFileType(fileType: string): string {
    const types = {
      [FileType.PDF]: 'icon--file-pdf',
      [FileType.XLS]: 'icon--file-xls',
      [FileType.XLSX]: 'icon--file-xls',
    };
    return types[fileType] || 'icon--nodoc';
  }

  downloadDocumentById(id: string): void {
    this.downloadDocument.emit(id);
  }

}
