@if (navigationState$ | async; as navigation; ) {
  <div class="nav-wrapper" [class.lg]="lg">
    @if (navigation.bvg) {
      <app-nav-card [title]="'articles.navtitle.bvg' | translate"
                    [icon]="'circle-question.svg'"
                    [lg]="lg"
                    [active]="activePage === ContentPages.BVG"
                    (selected)="goTo(ContentPages.BVG)"></app-nav-card>
    }
    @if (navigation.advice) {
      <app-nav-card [title]="'articles.navtitle.advice' | translate"
                    [icon]="'circle-personal.svg'"
                    [lg]="lg"
                    [active]="activePage === ContentPages.ADVICE"
                    (selected)="goTo(ContentPages.ADVICE)"></app-nav-card>
    }
    @if (navigation.analytics) {
      <app-nav-card [title]="'articles.navtitle.analytics' | translate"
                    [icon]="'circle-bulb.svg'"
                    [lg]="lg"
                    [active]="activePage === ContentPages.ANALYTICS"
                    (selected)="goTo(ContentPages.ANALYTICS)"></app-nav-card>
    }
    @if (navigation.docs) {
      <app-nav-card [title]="'articles.navtitle.docs' | translate"
                    [icon]="'circle-library.svg'"
                    [lg]="lg"
                    [active]="activePage === ContentPages.DOCS"
                    (selected)="goTo(ContentPages.DOCS)"></app-nav-card>
    }
  </div>
}
<div class="pb-3xl"></div>
