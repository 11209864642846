import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Toast} from 'ngx-toastr';

enum ToastType {
  ERROR = 'toast-error',
  INFO = 'toast-info',
  SUCCESS = 'toast-success',
  WARNING = 'toast-warning',
}

@Component({
  selector: 'app-alert-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert-card.component.html',
  styleUrl: './alert-card.component.scss',
})
export class AlertCardComponent extends Toast {

  getComponentImage(): string {
    const toastTypeMap: {[key: string]: string} = {
      [ToastType.ERROR]: 'icon--status-error color-error',
      [ToastType.SUCCESS]: 'icon--status-success color-success',
      [ToastType.INFO]: 'icon--status-info color-info',
      [ToastType.WARNING]: 'icon--status-warning color-warning',
    };
    return toastTypeMap[this.toastPackage.toastType] || '';
  }

}
