import {
  createAction,
  props,
} from '@ngrx/store';
import {PortalInfo} from './portal.model';

export const loadPortalData = createAction(
  '[Portal] Load portal data',
);

export const uploadPortalData = createAction(
  '[Portal] Upload portal data',
);

export const portalDataLoaded = createAction(
  '[Portal] Data loaded',
  props<{data: PortalInfo}>(),
);

export const makeVote = createAction(
  '[Portal] Make vote',
  props<{accepted: boolean}>(),
);

export const votingDone = createAction(
  '[Portal] Voting done',
);

export const downloadDocument = createAction(
  '[Portal] Download document',
  props<{documentId: string}>(),
);

export const downloadDocumentDone = createAction(
  '[Portal] Download document done',
);
