import {
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import {
  provideRouter,
  RouterModule,
} from '@angular/router';

import {provideAnimations} from '@angular/platform-browser/animations';
import {provideStore} from '@ngrx/store';
import {provideEffects} from '@ngrx/effects';
import {
  HttpClient,
  provideHttpClient,
} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {
  TranslateLoader,
  TranslateModule,
  TranslateStore,
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {routes} from './app.routes';
import {store} from './store/store';
import * as authEffects from './store/auth/auth.effects';
import * as contactEffects from './store/contact/contact.effects';
import * as portalEffects from './store/portal/portal.effects';
import {DEFAULT_LANG_KEY} from './functions/translate-utils';
import {AlertCardComponent} from './components/alert-card/alert-card.component';

function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(RouterModule),
    provideRouter(routes),
    provideAnimations(),
    provideStore(store),
    provideEffects([
      authEffects,
      contactEffects,
      portalEffects,
    ]),
    provideHttpClient(),

    importProvidersFrom(ToastrModule.forRoot({
      toastComponent: AlertCardComponent,
    })),
    importProvidersFrom(TranslateModule.forChild({
      defaultLanguage: DEFAULT_LANG_KEY,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    })),
    TranslateStore,
    provideEnvironmentNgxMask(),
  ],
};
