import {Injectable} from '@angular/core';
import {
  combineLatest,
  filter,
  map,
  Observable,
  skipWhile,
  switchMap,
} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import {
  selectLoggedIn,
  selectLoginChecked,
} from '../store/auth/auth.selectors';
import {AppState} from '../store/store';
import {
  selectAdviceEnabled,
  selectAnalyticsEnabled,
  selectBvgEnabled,
  selectDocsEnabled,
  selectIsPortalDataLoaded,
} from '../store/portal/portal.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {

  constructor(private store: Store<AppState>, private router: Router) {
  }

  isLoggedInUser(): Observable<boolean> {
    return this.store.select(selectLoginChecked)
      .pipe(
        skipWhile(checkComplete => !checkComplete),
        switchMap(() => this.store.select(selectLoggedIn)),
      );
  }

  checkFlagAfterPortalDataLoaded(selector$: Observable<boolean>, route: ActivatedRouteSnapshot)
    : Observable<true | UrlTree> {
    return combineLatest([selector$, this.store.select(selectIsPortalDataLoaded)]).pipe(
      filter(([, dataLoaded]) => dataLoaded),
      map(([enabled]) => enabled || this.router.parseUrl(`/${this.getUserNameFrontRoute(route)}`)),
    );
  }

  canGoToBVG(route: ActivatedRouteSnapshot): Observable<true | UrlTree> {
    return this.checkFlagAfterPortalDataLoaded(this.store.select(selectBvgEnabled), route);
  }

  canGoToAnalytics(route: ActivatedRouteSnapshot): Observable<true | UrlTree> {
    return this.checkFlagAfterPortalDataLoaded(this.store.select(selectAnalyticsEnabled), route);
  }

  canGoToDocs(route: ActivatedRouteSnapshot): Observable<true | UrlTree> {
    return this.checkFlagAfterPortalDataLoaded(this.store.select(selectDocsEnabled), route);
  }

  canGoToAdvice(route: ActivatedRouteSnapshot): Observable<true | UrlTree> {
    return this.checkFlagAfterPortalDataLoaded(this.store.select(selectAdviceEnabled), route);
  }

  canActivateRouteForLoggedInUsers(route: ActivatedRouteSnapshot): Observable<true | UrlTree> {
    const isLoginUrl = this.router.parseUrl(`/${this.getUserNameFrontRoute(route)}/login`);
    return this.isLoggedInUser().pipe(
      map(loggedIn => loggedIn || isLoginUrl),
    );
  }

  isGuest(): Observable<boolean> {
    return this.isLoggedInUser().pipe(map(loggedIn => !loggedIn));
  }

  /**
   * Extracts the username from the route snapshot.
   * If the username is not found, returns a default value of '-'.
   * @param route - The ActivatedRouteSnapshot containing route information.
   * @returns The username extracted from the route or '-' if not found.
   */
  getUserNameFrontRoute(route: ActivatedRouteSnapshot): string {
    return route.params?.['username'] || '-';
  }

}
