import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {StatusMessage} from '../store/auth/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {

  constructor(private toastr: ToastrService) {
  }

  getAlertPositionClass(): string {
    return this.isWideScreen() ? 'toast-top-right' : 'toast-bottom-center';
  }

  isWideScreen(): boolean {
    return window.innerWidth > 640;
  }

  showError(e: StatusMessage): void {
    this.toastr.error(e.message, e.statusText, {positionClass: this.getAlertPositionClass(), timeOut: 100 * 1000});
  }

  showInfo(e: StatusMessage): void {
    this.toastr.info(e.message, e.statusText, {positionClass: this.getAlertPositionClass()});
  }

  showSuccess({message, statusText = 'Success'}: StatusMessage): void {
    this.toastr.success(message, statusText, {positionClass: this.getAlertPositionClass()});
  }

}
