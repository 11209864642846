import {ActionReducer, createReducer, on} from '@ngrx/store';
import * as AuthActions from './auth.actions';

export interface AuthState {
  loggedIn: boolean;
  loginError: string;
  loginChecked: boolean
}

export const initialState: AuthState = {
  loggedIn: false,
  loginChecked: false,
  loginError: '',
};

export const authReducer: ActionReducer<AuthState> = createReducer(
  initialState,
  on(AuthActions.loginDone, (state): AuthState => ({...state, loggedIn: true})),
  on(AuthActions.loginChecked, (state, {loggedIn}): AuthState => ({...state, loggedIn, loginChecked: true})),
  on(AuthActions.logout, (): AuthState => initialState),
);
