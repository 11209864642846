import {
  interval,
  Observable,
} from 'rxjs';
import {
  map,
  take,
} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import moment from 'moment';
import {
  Params,
  Router,
} from '@angular/router';

export const countdown = (start: number): Observable<number> => interval(1000).pipe(
  map(i => start - i),
  take(start + 1),
);

export const getHttpErrorMessage = (e: HttpErrorResponse): {message: string, statusText: string, status: number} => ({
  message: e.message,
  statusText: e.statusText,
  status: e.status,
});

export const formatDateISO = (dateISOStr: string): string => {
  const date = moment(dateISOStr);
  if (dateISOStr && date.isValid()) {
    return date.format('DD.MM.YYYY HH:mm');
  }
  return dateISOStr;
};

export async function navigateWithSelfData(
  router: Router,
  queryParams: Observable<Params>,
  path: string[],
): Promise<void> {
  let selfData = '';
  queryParams.subscribe(params => {
    selfData = params['selfData'];
  });
  await router.navigate(path, {queryParams: {selfData}});
}
