import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {HeaderComponent} from '../../components/header/header.component';
import {FooterContactComponent} from '../../components/footer-contact/footer-contact.component';
import {FooterInfoComponent} from '../../components/footer-info/footer-info.component';
import {VotingFormComponent} from '../../components/voting-form/voting-form.component';
import {ContentSectionsComponent} from '../../components/content-sections/content-sections.component';
import {HtmlContentComponent} from '../../components/html-content/html-content.component';
import {AppState} from '../../store/store';
import {makeVote} from '../../store/portal/portal.actions';
import {
  selectHomePage,
  selectPreviewMode,
  selectVotingFormData,
} from '../../store/portal/portal.selectors';
import {BackButtonComponent} from '../../components/back-button/back-button.component';
import {PageTitleComponent} from '../../components/page-title/page-title.component';
import {CommonSpacerComponent} from '../../components/common-spacer/common-spacer.component';

@Component({
  selector: 'app-voting',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterContactComponent,
    FooterInfoComponent,
    VotingFormComponent,
    ContentSectionsComponent,
    HtmlContentComponent,
    TranslateModule,
    BackButtonComponent,
    PageTitleComponent,
    CommonSpacerComponent,
    MatProgressSpinner],
  templateUrl: './voting.component.html',
  styleUrl: './voting.component.scss',
})
export class VotingComponent {

  constructor(private store: Store<AppState>) {
  }

  public votingFormData$ = this.store.select(selectVotingFormData);

  public homePage$ = this.store.select(selectHomePage);

  public previewMode$ = this.store.select(selectPreviewMode);

  handleVoting(accepted: boolean): void {
    this.store.dispatch(makeVote({accepted}));
  }

}
