<app-header></app-header>
<app-back-button class="block mb-lg"></app-back-button>
<div class="title-with-button">
  <app-page-title class="title">{{ 'analytics.header' | translate }}</app-page-title>
  @if (jsonObject) {
    <button mat-stroked-button class="mat-button--icon button--icon"
            [color]="'primary'"
            (click)="downloadAsPdfDocument()">
      <div class="button-content">
        <i class="icon icon--download icon--color--broker icon--size--20 mr--2"></i>
        {{ 'button.title.download_as_pdf' | translate }}
      </div>
    </button>
  }
</div>
@if (jsonObject) {
  <app-common-spacer></app-common-spacer>
  <div #analyticsRef>
    <app-analytics-header-info [labels]="jsonObject?.labels"
                               [personalData]="jsonObject?.personalData"></app-analytics-header-info>
    <app-common-spacer></app-common-spacer>
    <app-analytics-table [rootTranslationKey]="'pensionInfo'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.pensionInfo"></app-analytics-table>
    <app-analytics-table [rootTranslationKey]="'savings'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.savings"></app-analytics-table>
    <app-analytics-table [rootTranslationKey]="'riskBenefits'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.riskBenefits"></app-analytics-table>
    <app-analytics-table [rootTranslationKey]="'premium'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.premium"></app-analytics-table>
    <app-analytics-footer-info></app-analytics-footer-info>
  </div>
  <div class="pdf-mobile-mode" #analyticsMobileRef>
    <app-analytics-header-info [pdfMode]="true"
                               [labels]="jsonObject?.labels"
                               [personalData]="jsonObject?.personalData"></app-analytics-header-info>
    <app-common-spacer></app-common-spacer>
    <app-analytics-table [pdfMode]="true"
                         [rootTranslationKey]="'pensionInfo'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.pensionInfo"></app-analytics-table>
    <app-analytics-table [pdfMode]="true"
                         [rootTranslationKey]="'savings'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.savings"></app-analytics-table>
    <app-analytics-table [pdfMode]="true"
                         [rootTranslationKey]="'riskBenefits'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.riskBenefits"></app-analytics-table>
    <app-analytics-table [pdfMode]="true"
                         [rootTranslationKey]="'premium'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.premium"></app-analytics-table>
    <app-analytics-footer-info></app-analytics-footer-info>
  </div>
  <!-- Workaround for lib issues(Safari/iOS related): https://github.com/niklasvh/html2canvas/issues/2688-->
    <!-- allowTaint:true, useCORS:true, <img> tag(https://github.com/niklasvh/html2canvas/issues/1818), PNG format for icons not resolve problem -->
  <div class="pdf-mobile-mode" #analyticsModeIOSRef>
    <app-analytics-header-info [pdfMode]="true"
                               [iOsMode]="true"
                               [labels]="jsonObject?.labels"
                               [personalData]="jsonObject?.personalData"></app-analytics-header-info>
    <app-common-spacer></app-common-spacer>
    <app-analytics-table [pdfMode]="true"
                         [rootTranslationKey]="'pensionInfo'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.pensionInfo"></app-analytics-table>
    <app-analytics-table [pdfMode]="true"
                         [rootTranslationKey]="'savings'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.savings"></app-analytics-table>
    <app-analytics-table [pdfMode]="true"
                         [rootTranslationKey]="'riskBenefits'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.riskBenefits"></app-analytics-table>
    <app-analytics-table [pdfMode]="true"
                         [rootTranslationKey]="'premium'"
                         [labels]="jsonObject?.labels"
                         [tableData]="jsonObject?.premium"></app-analytics-table>
    <app-analytics-footer-info [iOsMode]="true"></app-analytics-footer-info>
  </div>
}
<app-common-spacer></app-common-spacer>
<app-content-sections></app-content-sections>
<div class="mt-3xl"></div>
<app-footer-info></app-footer-info>
