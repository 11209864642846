@if (brokerName$ | async; as name) {
  <div class="footer-info-wrapper">
    <span>{{ 'footer.powered_by_broker' | translate: ({broker: name}) }}</span>
  </div>
}
@if (language$ | async; as lang) {
  <div class="footer-info-wrapper pb--8">
    <span>{{ 'footer.translation_is_done_automatically' | translate:({language: ('langs.' + lang) | translate}) }}</span>
  </div>
}
