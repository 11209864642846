<app-form-wrapper [title]="'login.card_bg' | translate">
  <div class="md_mt-lg"></div>
  <div *ngIf="step === 1"
       class="form-step-1 flex-col align-items-start md_flex-row md_align-items-center">
    <button mat-raised-button
            color="primary"
            (click)="handleCodeGet()">{{ 'login.get_code' | translate }}
    </button>
    <div>
      <span *ngIf="communicationWay === CommunicationWay.EMAIL">{{ 'login.send_method_email' | translate }}</span>
      <span *ngIf="communicationWay === CommunicationWay.SMS">{{ 'login.send_method_sms' | translate }}</span>
    </div>
  </div>
  <div *ngIf="step === 2"
       class="form-step-2">
    <div class="explanation-section">
      <span
        *ngIf="communicationWay === CommunicationWay.EMAIL">{{ 'login.enter_code_from_email' | translate:({contact}) }}</span>
      <span
        *ngIf="communicationWay === CommunicationWay.SMS">{{ 'login.enter_code_from_sms' | translate:({contact}) }}</span>
    </div>
    <div class="code-wrapper">
      <input [formControl]="codeControl"
             matInput
             class="code-input"
             [placeholder]="'login.send_code_placeholder' | translate"
             [class.is-invalid]="codeControl.invalid"/>
      <button mat-raised-button
              color="primary"
              class="code-button"
              (click)="handleLogin()">
        {{ 'login.send_code' | translate }}
      </button>
    </div>
    <div class="code-request-repeat">
      {{ 'login.did_not_get_code' | translate }}
      <span
        *ngIf="(cooldown | async) > 0">{{ 'login.try_again_cooldown' | translate: ({cooldown: (cooldown | async)}) }}</span>
      <span *ngIf="(cooldown | async) === 0"
            class="link-action"
            (click)="handleTryAgain()"
            (keydown.enter)="handleTryAgain()">{{ 'login.try_again' | translate }}</span>
    </div>
  </div>
</app-form-wrapper>
