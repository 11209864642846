import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  mergeMap,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {saveAs} from 'file-saver';
import {
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {
  loginChecked,
  loginDone,
  logoutDone,
  requestError,
  requestSuccess,
} from '../auth/auth.actions';
import {selectIsPortalDataLoaded} from './portal.selectors';
import {
  downloadDocument,
  downloadDocumentDone,
  loadPortalData,
  makeVote,
  portalDataLoaded,
  uploadPortalData,
  votingDone,
} from './portal.actions';
import {getHttpErrorMessage} from '../../functions/utils';
import {selectLoggedIn} from '../auth/auth.selectors';
import {Constants} from '../../constants/constants';

enum HeaderName {
  ContentDisposition = 'Content-Disposition',
}

/**
 * Method: Generate file name for export file
 */
function generateFileName(prefix?: string, fileExtension?: string): string {
  const date = new Date().toDateString().split(' ').join(Constants.HYPHEN);
  const time = /\d{2}:\d{2}:\d{2}/.exec(new Date().toTimeString())[0];
  const secondPartOfTitle = date + Constants.HYPHEN + time + fileExtension;
  return prefix ? prefix + secondPartOfTitle : secondPartOfTitle;
}

/**
 * Method: Handle file name from response or generate
 */
function getFileNameFromResponseOrGenerate(response: HttpResponse<any>): string {
  const contentDisposition = response.headers.get(HeaderName.ContentDisposition);
  if (contentDisposition) {
    const contentDispositionParams = contentDisposition.split(Constants.SEMICOLON);
    if (contentDispositionParams[2]) {
      return decodeURIComponent(contentDispositionParams[2].trim().split('\'\'')[1]);
    }
    if (contentDispositionParams[1]) {
      return contentDispositionParams[1].trim().split(Constants.EQUALS)[1].replace(/"/g, '');
    }
  }
  return generateFileName();
}

export const loadDataAfterLogin = createEffect(
  (actions$ = inject(Actions), appStore = inject(Store)) => actions$.pipe(
    ofType(loginDone, loginChecked),
    withLatestFrom(appStore.select(selectIsPortalDataLoaded), appStore.select(selectLoggedIn)),
    filter(([, isDataLoaded, loggedIn]) => !isDataLoaded && loggedIn),
    map(() => loadPortalData()),
  ),
  {functional: true},
);

export const handleLoadPortalData = createEffect(
  (actions$ = inject(Actions)) => actions$.pipe(
    ofType(loadPortalData),
    map(() => uploadPortalData()),
    catchError(error => of(requestError(getHttpErrorMessage(error)))),
  ),
  {functional: true},
);

export const handleUploadPortalData = createEffect(
  (
    actions$ = inject(Actions),
    apiService = inject(ApiService),
    route = inject(ActivatedRoute),
  ) => actions$.pipe(
    ofType(uploadPortalData),
    exhaustMap(() => apiService.getPortalData().pipe(
      mergeMap(data => of(loginChecked({loggedIn: true}), portalDataLoaded({data}))),
      catchError(
        e => {
          if ([HttpStatusCode.Unauthorized].includes(e.status)) {
            return of(
              logoutDone({username: (route?.firstChild || route)?.snapshot.paramMap.get('username') || 'unknown'}),
              loginChecked({loggedIn: false}),
            );
          }
          return of(requestError(getHttpErrorMessage(e)));
        },
      ),
    )),
  ),
  {functional: true},
);

export const handleVoting = createEffect(
  (actions$ = inject(Actions), apiService = inject(ApiService)) => actions$.pipe(
    ofType(makeVote),
    exhaustMap(({accepted}) => apiService.makeVote(accepted).pipe(
      map(
        () => votingDone(),
      ),
      catchError(
        error => of(requestError(getHttpErrorMessage(error))),
      ),
    )),
  ),
  {functional: true},
);

export const handleDownloadDocument = createEffect(
  (actions$ = inject(Actions), apiService = inject(ApiService)) => actions$.pipe(
    ofType(downloadDocument),
    exhaustMap(({documentId}) => apiService.downloadDocument(documentId).pipe(
      tap((response: HttpResponse<any>) => {
        saveAs(
          response.body,
          getFileNameFromResponseOrGenerate(response),
        );
      }),
      map(() => downloadDocumentDone()),
      catchError(error => of(requestError(getHttpErrorMessage(error)))),
    )),
  ),
  {functional: true},
);

export const refreshPortalStateAfterVoting = createEffect(
  (actions$ = inject(Actions), translationService = inject(TranslateService)) => actions$.pipe(
    ofType(votingDone),
    concatMap(
      () => [
        requestSuccess({message: translationService.instant('voting.your_vote_was_recorded_successfully')}),
        loadPortalData(),
      ],
    ),
  ),
  {functional: true},
);
