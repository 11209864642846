import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {Store} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {selectBrokerLogo} from '../../store/contact/contact.selectors';
import {AppState} from '../../store/store';
import {HeaderPanelComponent} from '../header-panel/header-panel.component';
import {LangMenuComponent} from '../lang-menu/lang-menu.component';
import {SquareIconButtonComponent} from '../square-icon-button/square-icon-button.component';
import {logout} from '../../store/auth/auth.actions';
import {selectLoggedIn} from '../../store/auth/auth.selectors';

@Component({
  selector: 'app-header-menu-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, HeaderPanelComponent, LangMenuComponent,
    SquareIconButtonComponent, TranslateModule],
  templateUrl: './header-menu-dialog.component.html',
  styleUrl: './header-menu-dialog.component.scss',
})
export class HeaderMenuDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<HeaderMenuDialogComponent>,
    private store: Store<AppState>,
  ) {
  }

  readonly brokerLogo$ = this.store.select(selectBrokerLogo);

  readonly loggedIn$ = this.store.select(selectLoggedIn);

  onClose(): void {
    this.dialogRef.close();
  }

  handleLogout(): void {
    this.store.dispatch(logout());
    this.onClose();
  }

}
