export class Constants {

  public static PERCENT = '%';

  public static APOSTROPHE = '\'';

  public static DOT = '.';

  public static SEMICOLON = ';';

  public static HYPHEN = '-';

  public static DASH = '—';

  public static EQUALS = '=';

  public static CHF = 'CHF';

  public static SWISS_PHONE_MASK = '+00 00 000 00 0099';

  public static FORMAT_DATE_PPL = 'dd.MM.yyyy';

}
