import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-square-icon-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './square-icon-button.component.html',
  styleUrl: './square-icon-button.component.scss',
})
export class SquareIconButtonComponent {

  @Input() icon = 'menu';

  @Input() color = 'primary';

  @Output() clicked = new EventEmitter<void>();

  handleClick(): void {
    this.clicked.emit();
  }

}
