import {
  createAction,
  props,
} from '@ngrx/store';

export const initializeApp = createAction(
  '[Auth] Initialize',
);

export const login = createAction(
  '[Auth] Login',
  props<{username: string; password: string}>(),
);

export const loginDone = createAction(
  '[Auth] Login Done',
  props<{username: string}>(),
);

export const logout = createAction(
  '[Auth] Logout',
);

export const logoutDone = createAction(
  '[Auth] Logout done',
  props<{username: string}>(),
);

export const loginChecked = createAction(
  '[Auth] Login checked',
  props<{loggedIn: boolean}>(),
);

export const requestCode = createAction(
  '[Auth] Request code',
  props<{username: string}>(),
);

export const codeRequestDone = createAction(
  '[Auth] Code request done',
);

export const requestError = createAction(
  '[Auth] Request error',
  props<{message: string, statusText?: string}>(),
);

export const requestSuccess = createAction(
  '[Auth] Request success',
  props<{message: string, statusText?: string}>(),
);

export const dataLoadAccessDenied = createAction(
  '[Auth] Data load access denied',
);

export const notFoundRoute = createAction(
  '[Auth] Not Found',
  props<{username: string}>(),
);
