<app-header-panel [logo]="brokerLogo$ | async">
  @if (loggedIn$ | async; as data) {
    <div class="separator hidden sm_block"></div>
    <button mat-stroked-button class="hidden sm_block" [color]="'primary'" (click)="handleLogout()">
      {{ 'header.log_out' | translate }}
    </button>
  }
  <app-square-icon-button
    class="sm_hidden"
    [color]="'primary'"
    [icon]="'menu'"
    (clicked)="showMobileMenu()">
  </app-square-icon-button>
</app-header-panel>
<app-common-spacer></app-common-spacer>
