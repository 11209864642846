import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import {NumberConverter} from '../functions/number-converter';
import {Constants} from '../constants/constants';

@Pipe({
  name: 'pasteApostropheAfterEachThreeNums',
  standalone: true,
})
export class PasteApostropheAfterEachThreeNumsPipe implements PipeTransform {

  transform(value: number): string | number {
    return value === null ? Constants.DASH : NumberConverter.pasteApostropheAfterEachThreeNums(value) || 0;
  }

}
