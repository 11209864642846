<div class="wrapper">
  <div class="image">
    <span class="icon icon--size--24"
          [class]="getComponentImage()"></span>
  </div>
  <div class="card-body body-md">
    <div class="title">{{title}}</div>
    <div class="message">{{message}}</div>
  </div>
</div>
