import {
  Component,
  OnInit,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {AppState} from '../../store/store';
import {HeaderComponent} from '../../components/header/header.component';
import {LoginFormComponent} from '../../components/login-form/login-form.component';
import {FooterContactComponent} from '../../components/footer-contact/footer-contact.component';
import {FooterInfoComponent} from '../../components/footer-info/footer-info.component';
import {
  selectCommunicationContact,
  selectCommunicationWay,
  selectLoginPageText,
} from '../../store/contact/contact.selectors';
import {HtmlContentComponent} from '../../components/html-content/html-content.component';
import {PageTitleComponent} from '../../components/page-title/page-title.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, HeaderComponent, LoginFormComponent, FooterContactComponent, FooterInfoComponent,
    HtmlContentComponent, TranslateModule, PageTitleComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) {
  }

  public username = '';

  public communicationWay$ = this.store.select(selectCommunicationWay);

  public communicationContact$ = this.store.select(selectCommunicationContact);

  public loginPageText$ = this.store.select(selectLoginPageText);

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.username = params?.['username'];
    });
  }

}
