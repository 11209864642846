export enum Language {
  en = 'en',
  de = 'de',
  fr = 'fr',
  it = 'it',
  pt = 'pt',
  es = 'es',
  sr = 'sr',
  hr = 'hr',
  sq = 'sq',
}

export const ALL_LANGUAGES = [Language.en, Language.de, Language.fr, Language.it, Language.pt, Language.es,
  Language.sr, Language.hr, Language.sq];
