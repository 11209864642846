import {Constants} from '../constants/constants';

export class NumberConverter {

  /**
   * Method: For correct calculation round number for float numeric (default 0 digits after comma)
   * @param value
   * @param digitsAfterComma
   */
  public static roundNumber(value: number, digitsAfterComma?: number): string {
    return (Math.round((+value + Number.EPSILON) * 100) / 100).toFixed(digitsAfterComma || 0);
  }

  /**
   * Method: Add apostrophe every tree numbers
   * @param value
   */
  public static pasteApostropheAfterEachThreeNums(value: number): string {
    if (!!value && value?.toString().length && !Number.isNaN(+value)) {
      const numArr = value.toString().split(Constants.DOT);
      if (numArr[0]) {
        numArr[0] = numArr[0].match(/-?\d{1,3}(?=(\d{3})*$)/g).join(Constants.APOSTROPHE);
      }
      return numArr.join();
    }
    return '';
  }

}
