import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import {Store} from '@ngrx/store';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {isEmpty} from 'lodash';
import {
  filter,
  map,
  switchMap,
} from 'rxjs';
import {AppState} from './store/store';
import {initializeApp} from './store/auth/auth.actions';
import {selectPreviewMode} from './store/portal/portal.selectors';
import {postSelfData} from './store/contact/contact.actions';
import {getActualLanguage} from './functions/translate-utils';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatDividerModule, MatIconModule, MatButtonModule, TranslateModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

  title = 'voting-portal-front';

  public previewMode$ = this.store.select(selectPreviewMode);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private translateService: TranslateService,
  ) {
    this.initTranslations();
    this.store.dispatch(initializeApp());
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd && event.url.includes('preview')),
      switchMap(() => this.activatedRoute.queryParams),
      filter(data => !isEmpty(data)),
      map(data => postSelfData({data})),
    ).subscribe(action => this.store.dispatch(action));
  }

  private initTranslations(): void {
    this.translateService.use(getActualLanguage());
  }

}
