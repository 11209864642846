import {Component} from '@angular/core';
import {
  CommonModule,
  ViewportScroller,
} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FooterContactComponent} from '../../components/footer-contact/footer-contact.component';
import {FooterInfoComponent} from '../../components/footer-info/footer-info.component';
import {HeaderComponent} from '../../components/header/header.component';
import {ContentSectionsComponent} from '../../components/content-sections/content-sections.component';
import {HtmlContentComponent} from '../../components/html-content/html-content.component';
import {BackButtonComponent} from '../../components/back-button/back-button.component';
import {PageTitleComponent} from '../../components/page-title/page-title.component';
import {CommonSpacerComponent} from '../../components/common-spacer/common-spacer.component';

@Component({
  selector: 'app-bvg',
  standalone: true,
  imports: [CommonModule, FooterContactComponent, FooterInfoComponent, HeaderComponent, ContentSectionsComponent,
    TranslateModule, HtmlContentComponent, BackButtonComponent, PageTitleComponent, CommonSpacerComponent],
  templateUrl: './bvg.component.html',
  styleUrl: './bvg.component.scss',
})
export class BvgComponent {

  constructor(private viewportScroller: ViewportScroller) {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

}
