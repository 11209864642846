import {Component} from '@angular/core';
import {
  CommonModule,
  ViewportScroller,
} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {ContentSectionsComponent} from '../../components/content-sections/content-sections.component';
import {HeaderComponent} from '../../components/header/header.component';
import {AppState} from '../../store/store';
import {selectBrokerName} from '../../store/contact/contact.selectors';
import {HtmlContentComponent} from '../../components/html-content/html-content.component';
import {
  selectBrokerAdvice,
  selectPensionCommission,
} from '../../store/portal/portal.selectors';
import {BackButtonComponent} from '../../components/back-button/back-button.component';
import {PageTitleComponent} from '../../components/page-title/page-title.component';
import {CommonSpacerComponent} from '../../components/common-spacer/common-spacer.component';
import {FooterInfoComponent} from '../../components/footer-info/footer-info.component';

@Component({
  selector: 'app-advice',
  standalone: true,
  imports: [CommonModule, ContentSectionsComponent, HeaderComponent, TranslateModule, HtmlContentComponent,
    BackButtonComponent, PageTitleComponent, CommonSpacerComponent, FooterInfoComponent],
  templateUrl: './advice.component.html',
  styleUrl: './advice.component.scss',
})
export class AdviceComponent {

  readonly brokerName$ = this.store.select(selectBrokerName);

  readonly pensionCommission$ = this.store.select(selectPensionCommission);

  readonly brokerAdvice$ = this.store.select(selectBrokerAdvice);

  constructor(private store: Store<AppState>, private viewportScroller: ViewportScroller) {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

}
