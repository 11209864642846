<div class="wrapper">
  <app-header-panel [logo]="brokerLogo$ | async">
    <app-square-icon-button class="sm_hidden"
                            [color]="'primary'"
                            [icon]="'close'"
                            (clicked)="onClose()"></app-square-icon-button>
  </app-header-panel>
  <div class="header-menu">
    <app-lang-menu class="lang-menu" [fullWidth]="true"></app-lang-menu>
    @if (loggedIn$ | async) {
      <button mat-stroked-button [color]="'primary'" (click)="handleLogout()">
        {{ 'header.log_out' | translate }}
      </button>
    }
  </div>
</div>
