import {inject} from '@angular/core';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  of,
  withLatestFrom,
} from 'rxjs';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  Router,
} from '@angular/router';
import {HttpStatusCode} from '@angular/common/http';
import {decompressFromEncodedURIComponent} from 'lz-string';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '../../services/api.service';
import {
  contactDataLoaded,
  loadContactData,
  loadContactDataOrPortalDataAfterSwitchLanguage,
  postSelfData,
} from './contact.actions';
import {getHttpErrorMessage} from '../../functions/utils';
import {selectLoggedIn} from '../auth/auth.selectors';
import {
  loadPortalData,
  portalDataLoaded,
} from '../portal/portal.actions';
import {
  dataLoadAccessDenied,
  notFoundRoute,
  requestError,
} from '../auth/auth.actions';
import {selectIsPublicDataLoaded} from './contact.selectors';
import {PortalInfo} from '../portal/portal.model';
import {ContactInfo} from './contact.model';

export const loadPublicDataset = createEffect(
  (
    actions$ = inject(Actions),
    apiService = inject(ApiService),
    route = inject(ActivatedRoute),
  ) => actions$.pipe(
    ofType(loadContactData),
    map(() => (route?.firstChild || route)?.snapshot.paramMap.get('username')),
    filter(username => !!username),
    exhaustMap(username => apiService.getContactData(username).pipe(
      map(data => contactDataLoaded({data})),
      catchError(
        e => {
          if ([HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(e.status)) {
            return of(dataLoadAccessDenied());
          }
          if ([HttpStatusCode.NotFound].includes(e.status)) {
            return of(notFoundRoute({username}));
          }
          return of(requestError(getHttpErrorMessage(e)));
        },
      ),
    )),
  ),
  {functional: true},
);

export const loadContactDataOrPortalData = createEffect(
  (
    actions$ = inject(Actions),
    appRouter = inject(Router),
    appStore = inject(Store),
  ) => actions$.pipe(
    ofType(loadContactDataOrPortalDataAfterSwitchLanguage),
    withLatestFrom(appStore.select(selectLoggedIn)),
    map(([, loggedIn]) => {
      if (appRouter.url.endsWith('login')) {
        return loadContactData();
      }
      if (loggedIn) {
        return loadPortalData();
      }
      return loadContactData();
    }),
  ),
  {functional: true},
);

function extractPortalInfo(parsedData: any): PortalInfo {
  return {
    previewMode: true,
    deadLineAt: parsedData?.participationLaw?.invitation?.deadlineAt,
    deadLineEnabled: parsedData?.participationLaw?.invitation?.deadlineEnabled,
    homePage: parsedData?.participationLaw?.content?.homePage,
    language: parsedData?.language,
    bvgEnabled: parsedData?.participationLaw?.content?.bvgEnabled,
    personalAnalytic: parsedData?.participationLaw?.content?.personalAnalytic,
    pensionAdviseEnabled: parsedData?.participationLaw?.content?.pensionAdvice?.enabled,
    additionalDocuments: parsedData?.participationLaw?.content?.additionalDocuments,
    documents: parsedData?.additionalDocuments,
    pensionCommission: parsedData?.participationLaw?.content?.pensionAdvice?.pensionCommission,
    brokerAdvise: parsedData?.participationLaw?.content?.pensionAdvice?.brokerAdvice,
  };
}

function extractContactInfo(parsedData: any): ContactInfo {
  return {
    brokerName: parsedData?.brokerCompany?.name,
    brokerLogo: parsedData?.brokerCompany?.logo,
    language: parsedData?.language,
    contactPerson: {
      fname: parsedData?.participationLaw?.contactPerson?.fname,
      phone: parsedData?.participationLaw?.contactPerson?.phone,
      role: parsedData?.participationLaw?.contactPerson?.role,
      email: parsedData?.participationLaw?.contactPerson?.email,
    },
  };
}

export const handlePostSelfData = createEffect(
  (actions$ = inject(Actions), translateService = inject(TranslateService)) => actions$.pipe(
    ofType(postSelfData),
    map((action: {type: string; data: Params}) => {
      const {selfData} = action.data;
      if (!selfData) {
        return of(requestError({message: translateService.instant('error.message.page_load_error')}));
      }
      const decompressedData = decompressFromEncodedURIComponent(selfData);
      const parsedData = JSON.parse(decompressedData);
      const portalInfoData: PortalInfo = extractPortalInfo(parsedData);
      const contactInfoData: ContactInfo = extractContactInfo(parsedData);
      return of(portalDataLoaded({data: portalInfoData}), contactDataLoaded({data: contactInfoData}));
    }),
    concatMap(action => action),
  ),
  {functional: true},
);

export const checkContactDataLoadedForUser = createEffect(
  (
    appRouter = inject(Router),
  ) => appRouter.events.pipe(
    filter(event => event instanceof NavigationEnd && appRouter.url.endsWith('login')),
    map(() => loadContactData()),
  ),
  {functional: true},
);

export const checkRouterContactDataLoaded = createEffect(
  (
    appRouter = inject(Router),
    activatedRoute = inject(ActivatedRoute),
    appStore = inject(Store),
  ) => appRouter.events.pipe(
    filter(() => !!(activatedRoute?.firstChild || activatedRoute)?.snapshot.paramMap.get('username')),
    withLatestFrom(appStore.select(selectIsPublicDataLoaded)),
    filter(([, isDataLoaded]) => !isDataLoaded),
    map(() => loadContactData()),
  ),
  {functional: true},
);
