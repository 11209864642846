import {
  Component,
  Input,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {
  getActualLanguage,
  saveLang,
} from '../../functions/translate-utils';
import {
  ALL_LANGUAGES,
  Language,
} from '../../enums/language.enum';
import {AppState} from '../../store/store';
import {loadContactDataOrPortalDataAfterSwitchLanguage} from '../../store/contact/contact.actions';
import {selectPreviewMode} from '../../store/portal/portal.selectors';

@Component({
  selector: 'app-lang-menu',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule, TranslateModule],
  templateUrl: './lang-menu.component.html',
  styleUrl: './lang-menu.component.scss',
})
export class LangMenuComponent {

  @Input() fullWidth = false;

  public allLanguages = ALL_LANGUAGES;

  public previewMode$ = this.store.select(selectPreviewMode);

  constructor(public translateService: TranslateService, private store: Store<AppState>) {
    this.initTranslations();
  }

  private initTranslations(): void {
    this.translateService.use(getActualLanguage());
  }

  onSetLang(language: Language): void {
    if (language !== this.translateService.currentLang) {
      saveLang(language);
      this.initTranslations();
      this.store.dispatch(loadContactDataOrPortalDataAfterSwitchLanguage());
    }
  }

}
