import {TranslateService} from '@ngx-translate/core';
import {Injector} from '@angular/core';
import {
  ALL_LANGUAGES,
  Language,
} from '../enums/language.enum';

export const DEFAULT_LANG_KEY = Language.de;
const LANG_STORAGE_KEY = 'preferredLang';

export function getActualLanguage(): string {
  const injector = Injector.create({providers: [{provide: TranslateService, deps: []}]});
  const translateService = injector.get(TranslateService);
  const browserLang = translateService.getBrowserLang();
  return localStorage.getItem(LANG_STORAGE_KEY) || (ALL_LANGUAGES.includes(browserLang as Language)
    ? browserLang : DEFAULT_LANG_KEY);
}

export function saveLang(lang: Language): void {
  localStorage.setItem(LANG_STORAGE_KEY, lang);
}
