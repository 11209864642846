import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {LangMenuComponent} from '../lang-menu/lang-menu.component';

@Component({
  selector: 'app-header-panel',
  standalone: true,
  imports: [CommonModule, LangMenuComponent, MatButtonModule, MatIconModule, TranslateModule],
  templateUrl: './header-panel.component.html',
  styleUrl: './header-panel.component.scss',
})
export class HeaderPanelComponent {

  @Input() logo: string | null = '';

}
