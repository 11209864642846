<div class="contact-wrapper flex flex-col md_flex-row mb--6">
  <div class="recommendation body-lg md_title-xl">
    {{ 'footer.contact.advice' | translate }}
  </div>
  <div class="separator md_block hidden"></div>
  @if (contact$ | async; as contact) {
    <div class="contact-card">
      @if (contact.avatar) {
        <img class="avatar" [alt]="'contact person'" [src]="contact.avatar"/>
      }
      @if (previewMode$ | async) {
        <i class="icon icon--avatar-default icon--size--72 mr--3"></i>
      }
      <div class="details">
        @if (contact.fname) {
          <div class="contact-name">{{ contact.fname }}
          </div>
        }
        @if (contact.role) {
          <div class="contact-role mb--2">{{ contact.role }}
          </div>
        }
        @if (contact.phone) {
          <div class="contact-phone">{{ (contact.phone || '') | mask:PHONE_MASK }}
          </div>
        }
        @if (contact.email) {
          <div class="contact-email">{{ contact.email }}
          </div>
        }
      </div>
    </div>
  }
</div>
