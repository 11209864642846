import {
  ActionReducer,
  createReducer,
  on,
} from '@ngrx/store';
import * as PortalActions from './portal.actions';
import {PortalState} from './portal.model';

export const initialState: PortalState = {
  portalData: undefined,
};

export const portalReducer: ActionReducer<PortalState> = createReducer(
  initialState,
  on(PortalActions.portalDataLoaded, (state, {data}): PortalState => ({...state, portalData: data})),
);
