import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatRippleModule} from '@angular/material/core';

@Component({
  selector: 'app-nav-card',
  standalone: true,
  imports: [CommonModule, MatRippleModule],
  templateUrl: './nav-card.component.html',
  styleUrl: './nav-card.component.scss',
})
export class NavCardComponent {

  @Input() lg = false;

  @Input() active = false;

  @Input() icon = '';

  @Input() title = '';

  @Output() selected = new EventEmitter<void>();

  handleSelect(): void {
    this.selected.emit();
  }

  getBgImageStyle(): string {
    return this.icon ? `background-image: url(/assets/icons/${this.icon})` : '';
  }

}
