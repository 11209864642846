import {inject} from '@angular/core';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  of,
  tap,
} from 'rxjs';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '../../services/api.service';
import {
  codeRequestDone,
  dataLoadAccessDenied,
  initializeApp,
  login,
  loginChecked,
  loginDone,
  logout,
  logoutDone,
  notFoundRoute,
  requestCode,
  requestError,
  requestSuccess,
} from './auth.actions';
import {getHttpErrorMessage} from '../../functions/utils';
import {AlertsService} from '../../services/alerts.service';
import {StatusMessage} from './auth.model';

export const handleInit = createEffect(
  (actions$ = inject(Actions), apiService = inject(ApiService)) => actions$.pipe(
    ofType(initializeApp),
    exhaustMap(() => apiService.getPortalData().pipe(
      map(() => loginChecked({loggedIn: true})),
      catchError(() => of(loginChecked({loggedIn: false}))),
    )),
  ),
  {functional: true},
);

export const doLogin = createEffect(
  (
    actions$ = inject(Actions),
    apiService = inject(ApiService),
    translateService = inject(TranslateService),
  ) => actions$.pipe(
    ofType(login),
    exhaustMap(({username, password}) => apiService.login({username, password}).pipe(
      mergeMap(() => of(loginChecked({loggedIn: true}), loginDone({username}))),
      catchError(() => of(requestError({
        message: translateService.instant('login.invalid_code_try_again'),
        statusText: translateService.instant('login.invalid_login_code'),
      }))),
    )),
  ),
  {functional: true},
);

export const doCodeRequest = createEffect(
  (actions$ = inject(Actions), apiService = inject(ApiService)) => actions$.pipe(
    ofType(requestCode),
    exhaustMap(({username}) => apiService.requestLoginCode(username).pipe(
      map(() => codeRequestDone()),
      catchError(error => of(requestError(getHttpErrorMessage(error)))),
    )),
  ),
  {functional: true},
);

export const navigateAfterLogin = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) => actions$.pipe(
    ofType(loginDone),
    tap(() => loginChecked({loggedIn: true})),
    tap(({username}) => router.navigate(['/', username]).then()),
  ),
  {functional: true, dispatch: false},
);

export const doLogout = createEffect(
  (
    actions$ = inject(Actions),
    apiService = inject(ApiService),
    route = inject(ActivatedRoute),
  ) => actions$.pipe(
    ofType(logout),
    exhaustMap(() => apiService.logout().pipe(
      map(
        () => logoutDone({username: (route?.firstChild || route)?.snapshot.paramMap.get('username') || 'unknown'}),
      ),
      catchError(error => of(requestError(getHttpErrorMessage(error)))),
    )),
  ),
  {functional: true},
);

export const navigateAfterLogout = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) => actions$.pipe(
    ofType(logoutDone),
    tap(() => loginChecked({loggedIn: false})),
    tap(({username}) => router.navigate(['/', username, 'login']).then()),
  ),
  {functional: true, dispatch: false},
);

export const handleRequestErrors = createEffect(
  (actions$ = inject(Actions), alertsService = inject(AlertsService)) => actions$.pipe(
    ofType(requestError),
    tap((e: StatusMessage) => alertsService.showError(e)),
  ),
  {functional: true, dispatch: false},
);

export const handleRequestSuccess = createEffect(
  (actions$ = inject(Actions), alerts = inject(AlertsService)) => actions$.pipe(
    ofType(requestSuccess),
    tap((e: StatusMessage) => alerts.showSuccess(e)),
  ),
  {functional: true, dispatch: false},
);

export const navigateToNoAccess = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) => actions$.pipe(
    ofType(dataLoadAccessDenied),
    tap(() => router.navigate(['no-access'])),
  ),
  {functional: true, dispatch: false},
);

export const navigateToNotFound = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) => actions$.pipe(
    ofType(notFoundRoute),
    tap(({username}) => router.navigate([`${username}/not-found`])),
  ),
  {functional: true, dispatch: false},
);
