export interface ContactInfo {
  loginPage?: string,
  brokerName: string,
  brokerLogo: string,
  communicationWaySent?: CommunicationWay,
  contact?: string,
  language: string,
  contactPerson: {
    avatar?: string,
    fname: string,
    phone: string,
    role: string,
    email: string
  }
}

export interface ContactState {
  contactData?: ContactInfo,
}

export enum CommunicationWay {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}
